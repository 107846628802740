<template>
  <b-modal
    id="modalCloseIncidence"
    :title="`¿Estás seguro de cerrar la incidencia ${incidence.name}?`"
    hide-footer
    no-close-on-backdrop
  >
    <!-- <validation-observer ref="closeIncidence">
      <validation-provider
        #default="{ errors }"
        :name="'Resolución Incidencia'"
        rules="required"
      > -->
        <b-form-group :label="'Resolución Incidencia'" label-for="description">
          <b-form-textarea v-model="description_resolution" />
        </b-form-group>
        <!-- <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </validation-observer> -->
    <div class="d-flex justify-content-between pt-2">
      <b-button variant="outline-danger"> Cancelar </b-button>
      <b-button @click="closeIncidence" variant="primary">
        Cerrar incidencia
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      description_resolution: "",
    };
  },
  computed: {
    ...mapGetters({
      incidence: "incidences/getIncidence",
    }),
  },
  methods: {
    ...mapActions({
      close: "incidences/close",
    }),
    async closeIncidence() {
      // const validate = await this.$refs.closeIncidence.validate();

      // if (!validate) return;

      await this.close({ id: this.incidence.id, params :{ description_resolution: this.description_resolution } })
      
      this.$root.$emit('bv::hide::modal', 'modalCloseIncidence')
    },
  },
};
</script>
